exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-confirmation-meeting-index-tsx": () => import("./../../../src/pages/confirmation-meeting/index.tsx" /* webpackChunkName: "component---src-pages-confirmation-meeting-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-recipe-index-tsx": () => import("./../../../src/pages/our-recipe/index.tsx" /* webpackChunkName: "component---src-pages-our-recipe-index-tsx" */),
  "component---src-pages-our-work-index-tsx": () => import("./../../../src/pages/our-work/index.tsx" /* webpackChunkName: "component---src-pages-our-work-index-tsx" */),
  "component---src-pages-services-cross-platform-app-development-js": () => import("./../../../src/pages/services/cross-platform-app-development.js" /* webpackChunkName: "component---src-pages-services-cross-platform-app-development-js" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../../../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-web-app-development-js": () => import("./../../../src/pages/services/web-app-development.js" /* webpackChunkName: "component---src-pages-services-web-app-development-js" */),
  "component---src-templates-blog-details-template-tsx": () => import("./../../../src/templates/blog-details-template.tsx" /* webpackChunkName: "component---src-templates-blog-details-template-tsx" */),
  "component---src-templates-case-study-template-tsx": () => import("./../../../src/templates/case-study-template.tsx" /* webpackChunkName: "component---src-templates-case-study-template-tsx" */)
}

